import React from 'react';
import { Link } from 'react-router-dom';

const LinkChecker  = ({children, href, uploads, routes}) => {

  const checkLink = () => {
    const isInternalLink = routes.includes(href);
    const isUploadLink = uploads.includes(href);
    const isMailToLink = href.includes('mailto:');
    const isExternalLink = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i.exec(href) ? true : false;

    if (isExternalLink || isMailToLink) {
      return <a href={href} target="_blank" rel="noopener noreferrer">{children[0]}</a>;
    }

    else if (isUploadLink) {
      return <a href={href}>{children[0]}</a>;
    }

    else if (isInternalLink) {
      return <Link to={href} title={children[0]}>{children[0]}</Link>;
    }

    else {
      return <b>!! FEHLERHAFTE VERLINKUNG !!</b>
    }

  }

  return <>{checkLink()}</>

}

export default LinkChecker;