import generatePermalink from '../helpers/generatePermalink';

const getPermalinkFromUploads = (props, needle) => {
  if (!props) return false;
  
  const foundUpload = props.uploads.find(upload => props[needle].includes(upload.hash));
  const foundUploadNameWithoutExtension = foundUpload ? foundUpload.name.replace(foundUpload.ext, '') : null;
  const foundUploadPermalink = foundUpload ? `/uploads/${generatePermalink(foundUploadNameWithoutExtension)}${foundUpload.ext}` : null;

  return foundUploadPermalink;
}

export default getPermalinkFromUploads;